.calendar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    z-index: 1000;
}

.calendar-container {
    background: #1e1f28;
    border-radius: 16px;
    padding: 20px;
    box-shadow: 0 8px 32px rgba(0,0,0,0.3);
    width: 320px;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    color: #fff;
    font-family: 'Inter-SemiBold';
}

.calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
}

.calendar-weekday {
    color: #686a75;
    text-align: center;
    font-size: 12px;
    padding: 5px;
}

.calendar-day {
    background: #2a2b35;
    color: #fff;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
}

.calendar-day:hover {
    background: #FF5722;
}