@font-face {
    font-family: 'Inter-Bold';
    src: local('Inter-Bold'), url(X:/DevResources/PUTEVOD/Crack/gotodream.ru/frontend/src/components/fonts/Inter-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter-Regular';
    src: local('Inter-Regular'), url(X:/DevResources/PUTEVOD/Crack/gotodream.ru/frontend/src/components/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: local('Inter-SemiBold'), url(X:/DevResources/PUTEVOD/Crack/gotodream.ru/frontend/src/components/fonts/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter-Light';
    src: local('Inter-SemiBold'), url(X:/DevResources/PUTEVOD/Crack/gotodream.ru/frontend/src/components/fonts/Inter-Light.ttf) format('truetype');
}

form {
    display: grid;
    grid-template-columns: 30% 5% 30% 5% ;
    grid-template-rows: 20% 30% 20%;
    /*grid-column: 1 / 6;*/
    /*grid-row: 1 / 5;*/
    /*height: 100vh;*/
}

input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
}

@keyframes slideDown {
    from {
        transform: translateY(-25%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.form-group {
    cursor: pointer;
    position: relative;
    background: #1e1f28;
    /*border: 1px solid #afafaf;*/
    border-radius: 16px;
}

.form-group:nth-child(1) {
    /*animation: slideDown 0.2s ease-out forwards;*/
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.form-group:nth-child(2) {
    /*animation: slideDown 0.4s ease-out forwards;*/
    grid-column: 3 / 4;
    grid-row: 1 / 2;
}

.form-group:nth-child(3) {
    /*animation: slideDown 0.6s ease-out forwards;*/
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}

.form-group:nth-child(4) {
    /*animation: slideDown 0.8s ease-out forwards;*/
    grid-column: 3 / 4;
    grid-row: 3 / 4;
}

.form-group:nth-child(6) {
    /*animation: slideDown 1.0s ease-out forwards;*/
    grid-column: 5 / 6;
    grid-row: 1 / 2;
}

.search-button {
    /*animation: slideDown 1.2s ease-out forwards;*/
    grid-column: 5 / 6;
    grid-row: 3 / 4;
    background: #ffffff;
    border-radius: 16px;
    font-family: 'Inter-SemiBold';
    font-size: 20pt;
    color: #131416;
    height: 10vh;
    margin: 5px 0px;
    padding: 10px;
    /*border: 3px solid #161c2c;*/
    cursor: pointer;
}

.search-button:hover{
    background: #dadce0;
}

/*.form-group label {*/
/*    position: absolute;*/
/*    top: 16px;*/
/*    left: 20px;*/
/*    font-family: 'Inter-Bold';*/
/*    font-size: 10pt;*/
/*    color: #c9c9c9;*/
/*}*/

/*.form-group input, .passenger-input {*/
/*    position: absolute;*/
/*    top: 40px;*/
/*    left: 20px;*/
/*    background: none;*/
/*    border: none;*/
/*    font-family: 'Inter-Regular';*/
/*    font-size: 14pt;*/
/*    color: #ffffff;*/
/*    width: calc(100% - 100px);*/
/*}*/

/*.form-group::after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    right: 20px;*/
/*    top: 50%;*/
/*    transform: translateY(-50%);*/
/*    width: 70px;*/
/*    height: 70px;*/
/*    !*background: #f2f2f4;*!*/
/*    border-radius: 8px;*/
/*}*/



/*.form-group:hover{*/
/*    border: 1px solid #afafaf;*/
/*    !*border: 1px solid transparent; !* Устанавливаем прозрачный бордер *!*!*/
/*    !*border-image: linear-gradient(90deg, #86a67d, #feb54e, #f65447) 1;*!*/
/*    border-radius: 16px; !* Скругление углов *!*/
/*}*/


/* Скрываем placeholder при фокусе */
.form-group input:focus::placeholder {
    color: transparent;
}

/* Индивидуальные иконки */
.form-group.from::after {
    background-image: url(X:/DevResources/PUTEVOD/Crack/gotodream.ru/frontend/src/components/assets/icons/departure.png);
}

.form-group.to::after {
    background-image: url(X:/DevResources/PUTEVOD/Crack/gotodream.ru/frontend/src/components/assets/icons/arrival.png);
}

.form-group.date::after{
    background-image: url(X:/DevResources/PUTEVOD/Crack/gotodream.ru/frontend/src/components/assets/icons/calender.png);
}

.form-group.passengers::after {
    background-image: url(X:/DevResources/PUTEVOD/Crack/gotodream.ru/frontend/src/components/assets/icons/passengers.png);
}


/* Дополнительные стили для модального окна и ссылок */
.form-title {
    color: white;
    font-family: 'Inter-Bold';
    margin-bottom: 2%;
}

.form-links {
    position: fixed;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 30px;
}

.form-link {
    color: white;
    text-decoration: none;
    font-family: 'Inter-Regular';
}

.passenger-input {
    color: white;
    cursor: pointer;
}

.passenger-dropdown {
    color: #ffffff;
    font-family: Inter-Light !important;
    position: absolute;
    background: #1e1f28;
    border-radius: 16px;
    padding: 20px;
    z-index: 1000;
}
input[type="checkbox"],
input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    margin: 0;
    padding: 0;
}

/*.form-group.date:nth-child(4) { !* 4-й элемент — поле возвращения *!*/
/*    display: none !important;*/
/*}*/
/*.........................................................................................................*/
/* Стили для сложного маршрута */
/* Анимация появления */
/*@keyframes slideDown {*/
/*    from {*/
/*        opacity: 0;*/
/*        transform: translateY(-10px);*/
/*    }*/
/*    to {*/
/*        opacity: 1;*/
/*        transform: translateY(0);*/
/*    }*/
/*}*/

.flight-segment {
    display: contents;
}

/* Контейнер для всей формы сложного маршрута */
.complex-form {
    display: grid;
    grid-template-rows: auto;
    row-gap: .2vh;
}

/* Контейнер для всех рейсов */
.flights-container {
    display: grid;
    grid-auto-rows: 80px; /* Фиксированная высота строк */
    gap: 10px;
}
/*.form-group {*/
/*    height: 80px; !* Фиксированная высота *!*/
/*    min-height: 80px;*/
/*    position: relative;*/
/*    background: #1e1f28;*/
/*    border-radius: 16px;*/
/*    padding: 10px;*/
/*    margin: 5px 0; !* Единые отступы *!*/
/*}*/
/*!* Убедитесь что все псевдоэлементы не влияют на высоту *!*/
/*.form-group::after {*/
/*    height: 60px;*/
/*    width: 60px;*/
/*}*/

/* Каждый рейс – отдельная строка */
/*.flight-row {*/
/*    display: grid;*/
/*    !* Задаём 5 колонок, где:*/
/*       1-я колонка - поле "Откуда",*/
/*       3-я - "Куда",*/
/*       5-я - "Дата".*/
/*       Остальные колонки можно оставить пустыми для отступов *!*/
/*    grid-template-columns: 30% 5% 30% 5% 30%;*/
/*    align-items: center; !* Вертикальное выравнивание по центру *!*/
/*}*/
.flight-row {
    display: grid;
    grid-template-columns: 30% 5% 30% 5% 30% 5%;
    position: relative;
}
/* Расположение полей внутри строки */
.flight-from {
    grid-column: 1;
}
/* Добавьте фиксированную высоту для каждой строки с полями */
.flight-row, .form-group {
    height: 8vh;
    min-height: 8vh;
}
.flight-to {
    grid-column: 3;
}

.flight-date {
    grid-column: 5;
}

/* Кнопка удаления рейса (если нужна) – можно разместить, например, в 6-й колонке, если добавите её в разметке */
/*.remove-flight {*/
/*    background: transparent;*/
/*    border: none;*/
/*    font-size: 50px;*/
/*    color: #FF5722;*/
/*    cursor: pointer;*/
/*}*/
.remove-flight {
    position: absolute;
    right: -3%;       /* Отступ от правого края поля даты */
    top: 3%;          /* Центрирование по вертикали */
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #FF5722;
    cursor: pointer;
    font-size: 40px;
    padding: 0;
    z-index: 2;        /* Чтобы крестик был поверх других элементов */
}
.flights-container {
    grid-template-columns: 30% 5% 30% 5% 30% 5%;
}
.form-group.from,
.form-group.to,
.form-group.date {
    min-width: 0; /* Позволяет сжимать контент внутри */
}
/* Кнопка добавления рейса – растянута по всем колонкам или по отдельной настройке */
.add-flight {
    grid-column: 1 / -1;
    background: #FF5722;
    border-radius: 16px;
    color: #ffffff;
    font-family: 'Inter-Bold';
    font-size: 16pt;
    cursor: pointer;
    padding: 10px;
    margin: 10px 0;
}

/* Нижний блок – поле пассажиров */
.passengers-row {
    display: grid;
    grid-template-columns: 30% 5% 30% 5% 30%;
    height: 60px;
}

.passengers-row .passengers {
    grid-column: 5;
    height: 100%;
}

/* Нижний блок – кнопка поиска */
.search-row {
    display: grid;
    grid-template-columns: 30% 5% 30% 5% 30%;
    height: 60px;
}

.search-row .search-button {
    grid-column: 5;
    height: 100%;
    background: #ffffff;
    border-radius: 16px;
    font-family: 'Inter-SemiBold';
    font-size: 20pt;
    color: #131416;
    cursor: pointer;
}

/* Общие стили для полей */
.form-group {
    position: relative;
    background: #1e1f28;
    border-radius: 16px;
    padding: 10px;
    margin: 5px 0;
}
.form-group, .passengers, .search-button{
    /*animation: slideDown 1.2s ease-out forwards;*/
}
.form-group label {
    position: absolute;
    top: 25%;
    left: 5%;
    font-family: 'Inter-Bold';
    font-size: 12pt;
    color: #c9c9c9;
}

.form-group input {
    position: absolute;
    top: 50%;
    left: 5%;
    background: none;
    border: none;
    font-family: 'Inter-Regular';
    font-size: 16pt;
    color: #ffffff;
    width: calc(100% - 100px);
}
.form-group::after {
    content: ""; /* Обязательно для псевдоэлемента */
    display: block;
    width: 20%; /* Укажите нужный размер */
    height: 70%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; /* Масштабирует иконку, сохраняя пропорции */
    position: absolute; /* Нужно для позиционирования */
    right: 5%; /* Регулируйте положение */
    top: 50%;
    transform: translateY(-50%);
}

/* Стили для неактивных полей */
.form-group input:disabled {
    opacity: 0.5 !important;
    background: #2a2b35 !important;
    cursor: not-allowed;
}

/* Затемнение label при неактивном поле */
.form-group input:disabled + label {
    opacity: 0.5;
}