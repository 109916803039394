/* RadialMenu.css */
:root {
    --delay: 0s;
}
@font-face {
    font-family: 'Ysabeu Sc';
    src: url('X:/DevResources/PUTEVOD/Crack/gotodream.ru/frontend/src/components/fonts/YsabeauSC-Light.ttf');
}

.radial-menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /*background: linear-gradient(45deg, #f0f2f5, #e1e4e8);*/
}

.radial-menu {
    position: relative;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    /*background: #1E1F28;*/
    /*background: rgba(255, 255, 255, 0.25);*/
    background: linear-gradient(45deg, #eff1f5, #d9dbdf);
    /*backdrop-filter: blur(12px);*/
    /*border: 2px solid rgba(255, 255, 255, 0.3);*/
    /*border: 2px solid rgba(255, 255, 255);*/
    /*box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);*/
}

.menu-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
}

.menu-item {
    position: absolute;
    width: 50%;
    height: 50%;
    transform-origin: 100% 100%;
    clip-path: polygon(100% 50%, 50% 100%, 0 50%, 50% 0);
    transition: all 0.3s ease;
    cursor: pointer;
    overflow: hidden;
}

/*.menu-item:hover .sector-mask {*/
/*    opacity: 10;*/
/*}*/

.sector-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(24, 144, 255, 0.15);
    clip-path: polygon(50% 50%, 100% 0%, 100% 100%, 50% 50%);
    opacity: 0;
    transition: opacity 0.3s ease;
}


.icon-wrapper {
    position: absolute;
    top: 40%;  /* Было 20% */
    left: 40%; /* Было 20% */
    transform-origin: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px; /* Было 60px */
    height: 60px; /* Было 60px */
}



.center-label {
    font-family: 'Ysabeu Sc';
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.2rem;
    font-weight: 600;
    /*color: #1890ff;*/
    padding: 12px 24px;
    /*background: rgba(255, 255, 255, 0.95);*/
    /*border-radius: 12px;*/
    /*box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);*/
    opacity: 0;
    transition: opacity 0.3s ease;
    /*backdrop-filter: blur(4px);*/
}

.menu-item:hover ~ .center-label {
    opacity: 1;
}

.menu-icon {
    width: 100px; /* Было 32px */
    height: 100px; /* Было 32px */
    /*filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));*/
    transition: transform 0.3s ease;
}

.menu-item:hover .menu-icon {
    transform: scale(1.2);
}

.menu-icon {
    opacity: 0;
    transform: scale(0.8);
}

/* Обновите CSS */
.menu-icon {
    opacity: 0;
    transform: scale(0.8);
}

.menu-icon.fade-in {
    animation: fadeIn 0.5s ease-in-out forwards;
    animation-delay: var(--delay);
}

@keyframes fadeIn {
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.menu-item:hover .menu-icon {
    transform: scale(1.2);
    transition: transform 0.3s ease !important;
}


